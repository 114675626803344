/* colors */
$primary: #1f35b5;
$primary-darker: #172888;
$primary-lighter: #dfe3f8;

$secondary: #22cbd3;
$secondary-darker: #1a989e;
$secondary-lighter: #d3f5f6;

$white: #ffffff;
$black: #000000;
$green: #4ac326;
$red: #ff4949;
$orange: #f9a435;
$yellow: #ffffb5;

$grey-one: #f5f5f5;
$grey-two: #ebebeb;
$grey-three: #e2e2e2;
$grey-four: #c1c1c1;
$grey-five: #acacac;
$grey-six: #898989;
$grey-seven: #4b4b4b;
$grey-eight: #c3c3c3;

$text: $grey-seven;
$title: $grey-seven;

$background: $white;
$background-alterantive: $grey-one;

/* viewports */
$mobile: 0;
$tablet: 736px;
$desktop: 1025px;

$content-max-width: 1096px;

/* grid */
$gutter: 20%;

/* font sizes */
$font-xs: 1.2rem;
$font-s: 1.5rem;
$font-d: 1.8rem;
$font-m: 2.1rem;
$font-l: 2.4rem;
$font-xl: 3.0rem;

$small-sizes: (null: $font-xs, $desktop: $font-xs);
$medium-sizes: (null: $font-m, $desktop: $font-m);
$subtitle-sizes: (null: $font-d, $desktop: $font-d);
$text-sizes: (null: $font-s, $desktop: $font-s);
$default-sizes: (null: $font-d, $desktop: $font-d);
$title-sizes: (null: $font-m, $desktop: $font-xl); // use it just for h1
$heading-sizes: (null: $font-l, $desktop: $font-l);

$font-ubuntu: 'Ubuntu';
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

$font-spacing-small: 0.1rem;
$font-spacing-medium: 0.2rem;
$font-spacing-large: 0.4rem;

/* Spacing */
$size-s: 1.2rem;
$size-m: 1.4rem;
$size-l: 1.6rem;
$size-xl: 1.8rem;

/* Radius */
$radius-s: 0.5rem;
$radius-l: 1.5rem;
