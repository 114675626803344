.CallEndButton {
  @extend %btn-rounded;
  background-color: $red;
  border-color: $red;

  &:hover {
    @extend %btn-rounded-hover; } }

.AltBox--endCall {
  display: none;

  @include desktop-media {
    background-color: $grey-seven;
    border-radius: 0.5rem;
    bottom: 6.5rem;
    color: $white;
    display: block;
    font-size: $font-xs;
    height: 3rem;
    line-height: 3rem;
    padding: 0 10px;
    position: absolute;
    right: 0.8rem; } }
