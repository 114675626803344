.App {
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  @include tablet-media {
    align-items: center; }

  @include desktop-media {
    align-items: center;

    &__container {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%; } } }
