.Tabs {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__item {
    border-bottom: 1px solid $grey-five;
    cursor: pointer;
    color: $grey-five;
    display: flex;
    justify-content: center;
    padding: 1.2rem 0 1.1rem;
    width: 100%;

    &-selected {
      @extend .Tabs__item;
      border-bottom: 2px solid $primary;
      color: $primary;

      p {
        color: $primary;
        font-weight: bold; } } } }
