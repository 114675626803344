.WaitingRoom {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: $content-max-width;
  padding: 3.64rem 2rem;
  width: 100%;

  @include desktop-media {
    align-items: flex-start;
    padding: 0; }

  &__main {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @include tablet-media {
      flex-wrap: nowrap;
      justify-content: space-between; }

    @include desktop-media {
      flex-wrap: nowrap;
      justify-content: space-between; } }

  & .LogoContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3rem;

    @include tablet-media {
      justify-content: flex-start; }

    @include desktop-media {
      justify-content: flex-start; }

    svg {
      overflow: visible; } } }

.VideoContainerBox {
  margin-bottom: 6.2rem;
  align-items: center;
  background-color: $grey-four;
  border-radius: 1rem;
  color: $white;
  display: flex;
  height: 19rem;
  justify-content: center;
  max-width: 64rem;
  position: relative;
  text-align: center;
  width: 100%;

  @include tablet-media {
    width: 100%;
    height: 34rem; }

  @include desktop-media {
    height: 34rem;
    width: 60rem; }

  &--disabled {
    @extend .VideoContainerBox;
    background-color: $grey-seven;

    & .LocalVideo {
      display: none; } } }

.FeedbackMessage {
  color: $white;
  position: absolute;
  width: 90%;

  @include desktop-media {
    font-size: $font-d; } }

.Controls {
  align-items: center;
  bottom: -2.8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;

  &__buttonContainer {
    align-items: center;
    background-color: $grey-four;
    border: 0.3rem solid $white;
    border-radius: 100%;
    display: flex;
    height: 6.1rem;
    justify-content: center;
    position: relative;
    width: 6.1rem;

    &:nth-child(n+2) {
      margin-left: 2.5rem; } } }

.LocalVideo {
  align-items: center;
  border-radius: 1rem;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  video {
    max-width: 100%;
    object-fit: contain;
    transform: scale(-1,1); } }

.FeedbackMessageManager {
  margin-bottom: 4.5rem;
  width: 100%;
  z-index: 10;

  @include tablet-media {
    margin-bottom: 4.5rem; }

  @include desktop-media {
    width: 45%; } }
