.CallControllers {
  align-items: center;
  animation: showControllers;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  background-color: $white;
  display: flex;
  flex-direction: row;
  height: 9rem;
  justify-content: center;
  position: relative;
  transition: all 1s ease;
  width: 100%;

  &--hide {
    @extend .CallControllers;
    animation: hideControllers;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out; }

  &__buttons {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 6rem;
    justify-content: space-between;
    max-width: 30rem;
    position: relative;
    width: 100%; }
  &__OptionsButton {
    top: -80px !important;
    width: 180px !important;
    &__AllOptions {
      top: -120px !important; } } }


@keyframes showControllers {
  0% {
    margin-bottom: -9rem; }
  100% {
    margin-bottom: 0; } }

@keyframes hideControllers {
  0% {
    margin-bottom: 0; }
  100% {
    margin-bottom: -9rem; } }


