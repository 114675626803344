.CallHeader {
  align-items: center;
  animation: showCallHeader;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  background-color: $white;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  -moz-box-shadow: 0px 3px 6px #00000029;
  display: flex;
  height: 5.1rem;
  justify-content: space-between;
  opacity: 1;
  padding: 0 2rem;
  transition: all 0.5s ease;
  width: 100%;

  &--hide {
    @extend .CallHeader;
    animation: hideCallHeader;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    opacity: 0; }

  @include tablet-media {
    border-radius: 1rem;
    max-width: 35.4rem; }

  @include desktop-media {
    border-radius: 1rem;
    max-width: 35.4rem; }

  &__guest-name {
    display: flex;
    flex-direction: column;
    height: 3rem;
    text-align: left;
    width: 50%;

    span {
      font-size: $font-xs;
      line-height: 1; }
    p {
      font-weight: $font-medium;
      line-height: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; } }

  &__timerbox {
    display: flex;
    height: 2rem;
    justify-content: flex-end;
    max-width: 50%;

    &__timeUp {
      color: $red;
      display: flex;
      flex-direction: column;
      font-size: $font-s;
      margin-left: 0.7rem;
      text-align: right;
      width: 80%; }

    &__timer {
      font-size: $font-s;
      margin-left: 0.7rem;

      @include tablet-media {
        font-size: $font-s; }

      @include desktop-media {
        font-size: $font-s; }

      &__seconds {
        color: #adadad; } } } }

@keyframes showCallHeader {
  0% {
    margin-top: -6rem; }
  50% {
    margin-top: 0;
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes hideCallHeader {
  0% {
    opacity: 1; }
  50% {
    margin-top: 0;
    opacity: 0; }
  100% {
    margin-top: -6rem; } }
