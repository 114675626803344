@keyframes Spinner {
	0% {
		-webkit-transform: translate(-50%, -50%) rotate(0deg);
		transform: translate(-50%, -50%) rotate(0deg); }

	100% {
		-webkit-transform: translate(-50%, -50%) rotate(360deg);
		transform: translate(-50%, -50%) rotate(360deg); } }

.Spinner {
	height: 42px;
	width: 42px;
	-webkit-transform: translate(-21px, -21px) scale(0.21) translate(21px, 21px);
	transform: translate(-21px, -21px) scale(0.21) translate(21px, 21px);

	&__icon,
	&__icon:after {
		border: 20px solid $primary;
		border-radius: 50%;
		border-top-color: transparent;
		height: 160px;
		position: absolute;
		width: 160px; }

	&__icon--white,
	&__icon--white:after {
		@extend .Spinner__icon;
		border-color: $white;
		border-top-color: transparent; }

	&__icon {
		-webkit-animation: Spinner 1s linear infinite;
		animation: Spinner 1s linear infinite;
		top: 100px;
		left: 100px; }

	&__icon:after {
		-webkit-transform: rotate(90deg);
		transform: rotate(90deg); } }
