.FeedbackMessageManager {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;

  .FeedbackMessageBox {
    align-items: center;
    color: $grey-seven;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 34rem;
    position: relative;
    text-align: center;
    white-space: break-spaces;
    width: 100%;

    &__icon {
      display: none;

      @include desktop-media {
        display: block; } }

    &__title {
      font-size: $font-m;
      font-weight: $font-medium;
      margin: 1rem; }

    &__message {
      margin-bottom: 3rem; }

    &__action {
      align-content: space-between;
      display: flex;

      #btn-exit-meet {
        width: 7rem; }

      #btn-start-call {
        margin-left: 1rem;
        width: 20.4rem; } } } }

