.TipsMessageBox {
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;

    &__title {
      display: block;
      font-weight: $font-bold;
      width: 16rem; }

    &__separator {
      background-color: $grey-two;
      flex-shrink: 3;
      height: 0.1rem;
      width: 100%; } }

  &__tips {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 1.5rem;
    position: relative;

    @include tablet-media {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between; }

    @include desktop-media {
      flex-direction: row;
      justify-content: space-between; }

    &__tipbox {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 1.5rem 0;

      &__message {
        margin-left: 2rem;
        text-align: left;
        flex-shrink: 3;
        font-weight: $font-light;
        max-width: 24.5rem;
        white-space: break-spaces; } } } }
