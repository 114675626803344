.ChatContainer {
  background-color: $white;
  box-shadow: -3px 0px 3px #00000029;
  -webkit-box-shadow: -3px 0px 3px #00000029;
  -moz-box-shadow: -3px 0px 3px #00000029;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: absolute;
  right: 0;
  transition: opacity 3s ease;
  width: 100%;
  z-index: 30;

  @include tablet-media {
    max-width: 30.1rem; }

  @include desktop-media {
    max-width: 30.1rem; }

  &__header {
    align-items: center;
    background-color: $white;
    color: $grey-seven;
    display: flex;
    font-size: $font-d;
    font-weight: $font-bold;
    justify-content: space-between;
    height: 6rem;
    padding: 2.6rem 2.5rem 0;
    width: 100%;

    &__icon {
      cursor: pointer;
      position: absolute;
      right: 0;
      z-index: 12; } }

  &__feedback-message {
    align-items: center;
    color: $grey-six;
    display: flex;
    height: 100%;
    justify-content: center;
    text-align: center;
    white-space: break-spaces;
    width: 100%; }

  &__content {
    display: block;
    height: 100%;
    overflow-y: scroll;
    overflow-wrap: break-word;
    padding: 0 2.5rem;
    position: relative;
    scroll-behavior: smooth;
    width: 100%;

    &__localMsg {
      margin-top: 1rem;
      position: relative;

      &--info {
        color: $grey-six;
        font-weight: $font-regular;
        font-size: $font-xs; } } }

  &__input {
    background-color: $white;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem 0.6rem;
    position: relative;
    width: 100%;

    input {
      @include ubuntu($font-regular);
      border: 0.1rem solid $grey-four;
      border-radius: 2.8rem;
      color: $grey-six;
      font-size: $font-s;
      height: 4.8rem;
      margin-right: 0.3rem;
      padding: 1.5rem;
      width: 80%; }

    button {
      @extend %btn-rounded;
      background-color: $primary;
      border-color: $primary;
      height: 4.8rem;
      width: 4.8rem;

      &:hover {
        @extend %btn-rounded-hover; }

      &:disabled {
        @extend %btn-rounded;
        background-color: $grey-two;
        border-color: $grey-two;
        height: 4.8rem;
        width: 4.8rem; } } }

  ::-webkit-scrollbar {
    display: none; } }

