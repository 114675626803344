.Reservation {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    margin-top: 20px;

    &__drawer {
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;

        & > div > a {
            top: 0; } }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center; }

    &__container {
        align-items: center;
        display: flex;
        flex-direction: column;
        color: #898989;
        width: 100%; }

    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%; }

    &__select {
        margin-top: 30px; }

    &__spinner {
        margin-top: 70px;
        &__fullScreen {
            margin-top: 50%; } }

    &__unavailability {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        padding: 20px 17px;
        border-radius: 5px;
        background-color: #F5F5F5;
        width: 100%;
        max-width: 330px;
        text-align: center; }

    &__unavailabilityTitle {
        max-width: 200px; }

    &__unavailabilityNote {
        margin-top: 20px !important; }

    &__timezone {
        text-align: center;
        max-width: 200px;
        margin-top: 20px;
        color: #898989;
        line-height: 16px !important; }

    &__button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        width: 100%;
        max-width: 330px; }

    &__success {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        &__icon {
            margin-bottom: 10px; } }

    &__successAgenda {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        gap: 5px; }

    &__exceptionsDates {
        margin-top: 20px;
        & > p:first-child {
            margin-bottom: 10px; } }

    &__feedback {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        padding: 20px 25px;
        border-radius: 5px;
        background-color: #FFFEB5;
        width: 100%;
        max-width: 330px;
        text-align: left; }

    &__feedbackText {
        margin-top: 20px !important; }

    &__restrictions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }

        &__paper {
            gap: 20px;
            margin-top: 20px; } } }

