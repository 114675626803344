.NetworkSignal {
  align-items: center;
  background-color: $white;
  border-radius: 1rem;
  display: flex;
  height: 3.2rem;
  justify-content: center;
  transition: all 0.5s ease-out;
  width: 4.1rem;

  &__container {
    align-items: baseline;
    display: flex;
    height: 1.7rem !important;
    width: 2.5rem; }

  &__bars {
    background-color: $green;
    border-radius: 0.20rem;
    height: 100%;
    margin-right: 2px;
    width: 3px;

    &-disabled {
      @extend .NetworkSignal__bars;
      background-color: $grey-five;

      &-red {
        @extend .NetworkSignal__bars-disabled;
        background-color: $red; } } } }

#signal-one {
  height: 0.9rem; }

#signal-two {
  height: 1.1rem; }

#signal-three {
  height: 1.3rem; }

#signal-four {
  height: 1.5rem; }

#signal-five {
  height: 1.7rem; }
