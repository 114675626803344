.ErrorViewMeeting {
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%;

  .LogoContainer {
    margin-bottom: 3rem;
    text-align: center;

    svg {
      overflow: visible; } }

  .ConnectionError,
  .BrowserError {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-items: center;
    padding: 0 1rem;
    text-align: center;
    width: 100%;

    &__title {
      font-size: $font-l;
      line-height: 1.2;
      margin-bottom: 2rem;
      white-space: break-spaces;
      text-align: center;

      @include tablet-media {
        font-size: $font-xl; }

      @include desktop-media {
        font-size: $font-xl; } }

    &__message {
      margin-bottom: 3rem;
      max-width: 36.4rem; }

    &__button {
      width: 22.8rem; }

    &__buttons {
      display: flex;
      justify-content: center;
      max-width: 60rem;
      width: 100%;

      .Button--reverse {
        border-color: $primary;
        color: $primary;
        font-size: $font-xs;
        height: 3.5rem;
        margin: 0 0.5rem;
        max-width: 15rem; } } } }
