.GuestEndCallModal__container {
  align-items: center;
  color: $grey-seven;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50rem;
  text-align: center;
  width: 100%;

  p {
    @include ubuntu($font-medium);
    font-size: $font-m; }

  &__button {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    width: 100%;

    .Button {
      margin: 0 1rem;
      max-width: 12rem; } } }
