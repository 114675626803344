html,
body, {
  height: 100%; }

body {
  @include ubuntu($font-regular);
  color: $text;
  font-size: $font-s;
  font-weight: $font-regular;
  line-height: 2.2rem;
  margin: 0; }

#root {
  height: 100%;
  overflow: auto; }
