.PatientDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  &__NameWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0 20px 0; }
  &__PersonalDataWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 34px;
    div {
      display: flex;
      align-items: center;
      gap: 10px; } }
  &__CloseButton {
    max-width: 332px;
    width: 100%; } }
