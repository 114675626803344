.FeedbackCallMessageBox {
  align-content: center;
  background-color: rgba(0, 0, 0, 0.5 );
  border-radius: 1rem;
  display: flex;
  color: $white;
  min-height: 7.7rem;
  justify-content: center;
  padding: 3rem 4rem;
  bottom: 50%;
  position: absolute;
  text-align: center;
  max-width: 90%;

  p {
    line-height: 1.2; } }
