.Button {
  @include ubuntu($font-bold);
  align-items: center;
  border: none;
  border-radius: 0.5rem;
  background-color: $primary;
  color: $white;
  display: flex;
  font-size: $font-s;
  height: 4.8rem;
  justify-content: center;
  max-width: 50rem;
  min-width: 7rem;
  padding: 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s;
  width: 100%;

  &:focus {
    outline: none; }

  &:hover,
  &:active {
    background-color: $primary-darker; }

  &:disabled {
    background-color: $grey-two;
    color: $grey-four; }

  &--reverse {
    @extend .Button;
    background-color: $white;
    border: 0.1rem solid $grey-seven;
    color: $grey-seven;

    &:hover {
      background-color: $grey-one; }

    &:disabled {
      background-color: $white;
      border-color: $grey-four; } } }
