.CallHeaderAlert {
  align-items: center;
  background-color: $red;
  color: $white;
  display: flex;
  height: 4.1rem;
  justify-content: space-between;
  margin-top: -0.1rem;
  padding: 0 2rem;
  transition: all 0.5s ease-out;
  width: 100%;
  z-index: -1;

  @include tablet-media {
    border-radius: 1rem;
    max-width: 35.4rem; }

  @include desktop-media {
    border-radius: 1rem;
    max-width: 35.4rem; }

  &__timerbox {
    display: flex;
    justify-content: flex-start;
    width: 30%;

    &__timer {
      font-weight: $font-bold;
      margin-left: 0.7rem;

      @include tablet-media {
        font-size: $font-s; }

      @include desktop-media {
        font-size: $font-s; } }

    &__text {
      display: flex;
      flex-direction: column;
      height: 3rem;
      text-align: right;
      width: 80%;

      span {
        font-size: $font-xs;
        line-height: 1; }
      p {
        line-height: 1; } } } }
