.CallRoom {
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  position: relative;
  transition: all 0.5s ease-out;

  &__components {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;

    &__header {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include tablet-media {
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        margin: 3rem 3rem 0;
        width: auto; }

      @include desktop-media {
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        margin: 3rem 3rem 0;
        width: auto; }

      &--left {
        display: flex;
        flex-direction: column;
        flex-grow: 1; }

      &--right {
        display: flex;
        flex-direction: row;
        margin: 1rem 1rem 0 0;
        justify-content: flex-end;

        @include tablet-media {
          margin: 0; }

        @include desktop-media {
          margin: 0; }

        .Participant__mic-off {
          align-items: center;
          background-color: $red;
          border-radius: 50%;
          display: flex;
          height: 3rem;
          justify-content: center;
          margin-right: 1rem;
          width: 3rem; } } }

    &__main {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-end;
      position: relative;
      width: 100%; }

    &__footer {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%; } }

  .LocalVideoContainer {
    background-color: $black;
    border: 0.2rem solid $white;
    border-radius: 1rem;
    display: flex;
    height: 14rem;
    margin: 0 0 2rem 2rem;
    position: relative;
    width: 8rem;

    align-self: flex-start;

    @include tablet-media {
      height: 11.6rem;
      margin: 0 0 2rem 2rem;
      width: 20.4rem; }

    @include desktop-media {
      height: 11.6rem;
      margin: 0 0 3rem 3rem;
      width: 20.4rem; }

    & .NetworkSignal {
      align-self: flex-end;
      left: -0.2rem;
      position: absolute;
      bottom: 0;
      z-index: 1; }

    video {
      border-radius: 8px;
      display: block;
      max-width: 100%;
      object-fit: cover;
      position: relative;
      transform: scale(-1,1);
      width: 100%; }

    &__icon {
      align-self: center;
      position: absolute;
      text-align: center;
      width: 100%;
      z-index: 2; } }

  .MainVideoContainer {
    align-items: center;
    background-color: $grey-seven;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    &.videoOff {
      video {
        opacity: 0; } }

    &.videoOn {
      video {
        opacity: 1; } }

    video {
      display: block;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      position: relative;
      width: auto;

      @include tablet-media {
        max-width: 100%;
        object-fit: contain; }

      @include desktop-media {
        max-width: 100%;
        object-fit: contain; } } } }
