.SettingsModal__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-weight: 500; }

  &__closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background: initial;
    border: initial;

    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:hover {
      background: $grey-two;
      transition: background 0.2s; } }

  &__tabs {
    width: 100%;
    margin-top: 30px;

    &__audioContainer, &__videoContainer {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      video {
        height: 100px;
        transform: scaleX(-1); }

      &--video-off {
        height: 100px;
        width: 120px;
        background: $black;

        display: flex;
        align-items: center;
        justify-content: center; }

      &__message {
        max-width: 255px;
        color: $grey-six !important; }

      &__selectLabel {
        width: 100%;
        margin-top: 30px !important; }

      &__select {
        width: 100%;

        &__button {
          background: initial;
          border: initial;

          display: flex;
          align-items: center;
          gap: 5px;

          &:hover {
            filter: brightness(1.3); } }

        &__button--disabled {
          background: initial;
          border: initial;

          display: flex;
          align-items: center;
          gap: 5px; }

        &--speaker {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 10px; }

        &--microphone {
          display: flex;
          align-items: flex-end;
          gap: 10px;

          > svg {
            border: 1px solid $grey-eight;
            border-radius: 10px;
            height: 48px;
            width: 12px; } }

        div {
          max-width: initial; } } } } }
