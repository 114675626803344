.RequestTokenModal__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__spinnerWrapper {
    background: $primary;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-bottom: 10px;
    margin: 0 auto;

    div {
      height: 27px !important;
      width: 27px !important;
      border-width: 3px !important;
      border-color: $white !important;
      border-top-color: transparent !important; } }

  &__title {
    font-weight: 500;
    text-align: center;
    padding-bottom: 30px; }

  &__redirectText {
    padding-top: 15px !important; }

  &__closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background: initial;
    border: initial;

    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:hover {
      background: $grey-two;
      transition: background 0.2s; } }

  &__tokenInputWrapper {
    width: 100%;
    margin-top: 20px; }

  &__message {
    text-align: left;
    width: 100%;
    margin: 10px 0px 20px !important; }

  &__submitButton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      border-color: $white;
      border-top-color: transparent;
      height: 30px;
      width: 30px; } }

  &__separator {
    width: 100%;
    height: 1px;
    border: initial;
    margin: 30px 0;
    background: $grey-two; }

  &__tokenInfoWrapper {
    ul {
      margin-top: 25px; }

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      margin-bottom: 20px;

      div {
        min-height: 30px;
        min-width: 30px;
        background-color: $primary;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: $white;
          font-weight: bold;
          font-size: 18px; } } } } }
