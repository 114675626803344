*,
*:before,
*:after {
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

button {
  cursor: pointer;
  font-family: $font-ubuntu;
  font-size: $font-s;

  &:disabled {
    cursor: default; }
  &:focus {
    outline: none; } }

input {
  &:focus {
    outline: none; } }

a {
  color: inherit;
  text-decoration: none;
  width: inherit; }

h1,
h2,
h4,
h5,
h6,
ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none; } }

p {
  margin-block-end: 0;
  margin-block-start: 0; }
