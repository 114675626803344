%btn-rounded {
  align-items: center;
  background-color: $white;
  border: 0.2rem solid $grey-four;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 5.6rem;
  justify-content: center;
  opacity: 1;
  position: relative;
  transition: 0.4s;
  width: 5.6rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent; }

%btn-rounded-hover {
  opacity: 0.7; }

%no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent; }
