/* Fonts */
@mixin font-size($sizes) {
  @each $fs-breakpoint, $fs-size in $sizes {
    @if $fs-breakpoint == null {
      font-size: $fs-size !important; }
    @else {
      @media screen and (min-width: $fs-breakpoint) {
        font-size: $fs-size !important; } } } }

@mixin ubuntu($weight: $font-regular) {
  font-family: $font-ubuntu;
  font-weight: $weight; }

/* Media Query */
@mixin desktop-media {
  @media (min-width: $desktop) {
    @content; } }

@mixin tablet-media {
  @media (min-width: $tablet) and (max-width: $desktop) {
    @content; } }

@mixin mobile-media {
  @media (min-width: $mobile) and (max-width: $tablet) {
    @content; } }
